// Implements live filtering
// Implements ARIA Live Region to announce that content on the page has updated as a result of user action
// https://www.scottohara.me/blog/2022/02/05/are-we-live.html

let filterType = document.querySelector("#filterType");
let productsWrapper = document.querySelector('.productsWrapper');
const productTypes = document.querySelectorAll(".productsWrapper > .cardSmall");


const doFiltering = () => {
	/* Early exit if function not needed */
	if (!productsWrapper) {
		console.info('There is no .productsWrapper, exiting function');
		return;
	}

	// console.log("---new run---");
	/*
		Explanation:
		- We build a `searchArray` which contains the parameters that the user is searching against, this is taken from the form inputs
		- We loop through each property type, and test its parameter values against the search parameters, to build a `matchArray`
		- We stringify both arrays so that we can then compare and see if they are identical - if so, the productType was a match for the search criteria
	*/

	productTypes.forEach(productTypeSingle => {
		// hide all on first run, visually and in the a11y tree
		// productTypeSingle.classList.add('uc_hide-for-everyone');
		productTypeSingle.setAttribute("aria-hidden", "true");
		// console.log( productTypeSingle );

		const searchArray = []; // what is the user looking for
		const matchArray  = []; // what matches on this plot

		// build the search array and Aria message. This only adds what PROPERTY needs to be matched, and not the VALUE. Tests are done independently of this array.
		if( filterType ) {
			if( filterType.value != "any" ) { searchArray.push('product-type'); }
		}

		// build the match array
		if( filterType ) {
			if( filterType.value == productTypeSingle.dataset.productType ) {
				matchArray.push('product-type');
			}
		}

		// if the searchArray is the same as the matchArray then the cottage was a complete match on all the searched items and should be shown
		if( JSON.stringify(searchArray) == JSON.stringify(matchArray) ) {
			productTypeSingle.classList.remove('uc_hide-for-everyone');
			productTypeSingle.setAttribute("aria-hidden", "false");
		} else {
			productTypeSingle.classList.add('uc_hide-for-everyone');
			productTypeSingle.setAttribute("aria-hidden", "true");
		}

		console.log( "searchArray", JSON.stringify(searchArray) );
		console.log( "matchArray", JSON.stringify(matchArray) );
	});

	let numberMatchedproductTypes = document.querySelectorAll('.productsWrapper > .cardSmall:not(.uc_hide-for-everyone)').length;

	if (filterType) {
		if (filterType.value != "any") {
		}
		else {
		}

		console.log( filterType.value );
	}

	// handle if no matches so it's a bit more friendly
	document.querySelector("#noMatches")?.remove();
	if( numberMatchedproductTypes == 0 ) {
		productsWrapper.insertAdjacentHTML('afterBegin', `<p id="noMatches">Sorry, there are no products of that type right now.</p>`);
	}
};

// watch for changes
	if( filterType ) {
		filterType.addEventListener('change', doFiltering);
	}

doFiltering();
